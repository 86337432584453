import "tabulator-tables";

export class TabulatorGrid {
    grid!: Tabulator;
    ref: any | undefined;
    context: any | undefined;
    gridOptions: any | undefined;
    reload: boolean | undefined;
    originalData: any | undefined;

    createGridByRef(ref: any, context: any) {
        let me = this;
        this.ref = ref;

        window.addEventListener('resize', this.resizeToParent.bind(this));
        this.resizeToParent();

        this.gridOptions = {
            clipboard: true,
            maxHeight: 'calc(100% - 8px)',
            layout: 'fitDataFill', //'fitDataTable', //'fitDataStretch', //'fitDataFill', //'fitData', //"fitColumns",
            columnHeaderVertAlign: 'bottom',
            resizableColumns: true,
            movableColumns: !(context.isPivot || context.grouping),
            layoutColumnsOnNewData: true,
            dataTreeCollapseElement: '<i class="material-icons">expand_less</i>',
            dataTreeExpandElement: '<i class="material-icons">expand_more</i>',
            dataTree: context.grouping, //!this.settings.isLeaf,
            dataTreeStartExpanded: false,
            dataTreeChildIndent: context.groupTreeChildIndent,
            dataTreeBranchElement: true, // Visible branch element?
            reactiveData: true,
            headerSort: !(context.grouping),
            selectable: true,
            selectableRangeMode: "click",
            invalidOptionWarnings: false,
            nestedFieldSeparator: false,
            tooltips: function (cell: any) {
                return cell.getElement().textContent;
            },
            downloadConfig: {
                columnCalcs: false
            },
            dataTreeRowExpanded: function (row: any, level: number) {
                let rowData = row.getData();
                let levelFilterValues: any[] = [];

                me.showLoaderOverlay();
                let currentRow = row;
                for (let i = 0; i < level + 1; i++) {
                    levelFilterValues.unshift(currentRow.getData()[me.context.defaultGroupFieldName].toString());
                    currentRow = currentRow.getTreeParent()
                }

                context.onDataTreeRowExpandedCaller
                    .invokeMethodAsync(context.onDataTreeRowExpandedFn, levelFilterValues, level)
                    .then((result: { hasMoreChildren: boolean, data: any[], isAnchor: boolean, isUseIcon: boolean }) => {
                        if (me.context.sumRows)
                            result.data = me.calcRows(result.data);

                        if (result.hasMoreChildren)
                            result.data.forEach(d => d._children = []); // Fake child data

                        rowData._children = result.data;

                        if (result.isAnchor) {
                            let value = result.data[0]["_group0"];
                            result.data[0]["_group0"] = `<a href="${value}" title="${value}">
                                                           ${result.isUseIcon ? '<i class="material-icons">open_in_new</i>' : value} </a>`;
                        }
                        me.hideLoaderOverlay();
                    });
            },
            columnMoved: function (column: any, columns: any[]) {
                context.onMovedColumnCaller
                    .invokeMethodAsync(context.onMovedColumnFn, column.getField(), columns.indexOf(column))
                    .then((r: any) => console.log(r));
            },
            columnResized: function (column: any) {
                context.
                    onResizedColumnCaller
                    .invokeMethodAsync(context.onResizedColumnFn, column.getField(), column.getWidth())
                    .then((r: any) => console.log(r));
            },
            rowSelected: function (row: any) {
                let rows = row.getTable().getRows();
                context.onSelectedRowCaller
                    .invokeMethodAsync(context.onSelectedRowFn, rows.indexOf(row), row.getData(), context.isPivot)
                    .then((r: any) => console.log(r));
            },
            rowFormatter: function (row: any) {
                row.getCells().forEach((v: any) => {
                    let a = v.getElement().querySelector('a');
                    if (a != null)
                        a.href = a.href.split('%20').join('-');
                });
            },
            cellClick: function (_e: any, cell: any) {
                let row = cell.getRow();
                let rows = row.getTable().getRows();
                let rowIndex = rows.indexOf(row);
                let value = cell.getValue();
                let field = cell.getField();

                context.onClickedCellCaller
                    .invokeMethodAsync(context.onClickedCellFn, field, rowIndex, value)
                    .then((r: any) => console.log(r));
            }
        };
        this.grid = new Tabulator(this.ref, this.gridOptions);
        this.context = context;
        this.grid.extendModule("format", "formatters", {
            customNumber: (cell: any, formatterParams: any) => {
                return this.customNumber(cell, formatterParams);
            },
            deltaNumber: (cell: any, formatterParams: any) => {
                return this.deltaNumber(cell, formatterParams);
            },
            customDatetime: (cell: any, formatterParams: any) => {
                return this.customDatetime(cell, formatterParams);
            }
        })
        return this;
    }

    customNumber(cell: any, formatterParams: any) {
        let value = cell.getValue();
        let definition = this.lookupDefinition(cell);
        let cellFormatterParams = this.getFormatterParams(definition);
        let isBigValue = this.getBigValue(definition);
        let isNumberOverride = cell.getData()["_forceformatter"] && cell.getData()["_forceformatter"] == "number"; // number is so far the only one implemented
        let realValue = this.getRealValue(value, isBigValue);
        let symbol = cellFormatterParams.symbol;
        let numberSymbol = this.getNumberSymbol(cell, isBigValue, value);
        let valueFormatted = this.number_format(realValue, cellFormatterParams.precision, cellFormatterParams.decimal ?? definition.formatterParams.decimal, cellFormatterParams.thousand);
        let fixedBigNumberFormat = definition.fixedBigNumberFormat > 0 ? definition.fixedBigNumberFormat : this.context.fixedBigNumberFormat;

        if (symbol == "%" && !isNumberOverride)
            return this.deltaNumber(cell, formatterParams);

        if (definition.valuesOnLastGroupOnly && this.isBlank(value)) {
            if (value == "NullValueExpression")
                return definition.nullValueExpression;
            else
                return "";
        }

        if (isNaN(Number(value)))
            return definition.nullValueExpression;

        if (this.isBlank(value))
            return definition.nullValueExpression;

        if (fixedBigNumberFormat > 0) {
            let prefix = definition.formatterParams?.prefix ?? this.context.numberFormat.formatterParams.prefix;

            switch (fixedBigNumberFormat) {
                case 1e+6:
                    numberSymbol = prefix.million;
                    break;
                case 1e+9:
                    numberSymbol = prefix.billion;
                    break;
                default:
                    numberSymbol = prefix.thousand;
                    break;
            }
            valueFormatted = this.number_format(value / fixedBigNumberFormat, cellFormatterParams.precision, cellFormatterParams.decimal ?? definition.formatterParams.decimal, cellFormatterParams.thousand);
        }    

        return valueFormatted + numberSymbol;
    }

    deltaNumber(cell: any, _formatterParams: any) {
        let cellValue = cell.getValue();
        let definition = this.lookupDefinition(cell);

        if (definition.valuesOnLastGroupOnly && this.isBlank(cellValue))
        {
            if (cellValue == "NullValueExpression")
                return definition.nullValueExpression;
            else
                return "";
        }

        if (isNaN(Number(cellValue)))
            return definition.nullValueExpression;

        if (this.isBlank(cellValue))
            return definition.nullValueExpression;

        let value = cellValue * 100;
        let cellFormatterParams = this.getFormatterParams(definition);
        let valueFormatted = this.number_format(value, cellFormatterParams.precision, cellFormatterParams.decimal, cellFormatterParams.thousand);
        return valueFormatted + "%";
    }

    customDatetime(cell: any, formatterParams: any) {
        let cellValue = cell.getValue();
        if (!cellValue)
            return;

        let value = new Date(cellValue);
        let lang = !this.context.applicationLanguage || this.context.applicationLanguage.length === 0 ? navigator.language : this.context.applicationLanguage;
        let date = Intl.DateTimeFormat(lang, formatterParams).format(value);

        if (formatterParams.quarter)
            return `Q${Math.floor((value.getMonth() + 3) / 3)} ${date}`;

        if (formatterParams.week) {
            let oneJan = new Date(value.getFullYear(), 0, 1);
            let numberOfDays = Math.floor((value.valueOf() - oneJan.valueOf()) / (24 * 60 * 60 * 1000));
            return `${date}-W${Math.ceil((value.getDay() + 1 + numberOfDays) / 7)}`;
        }

        return date;
    }

    lookupDefinition(cell: any) {
        let definition = cell?.getColumn().getDefinition();

        return definition?.formatterParams ? definition : this.lookupParentBottomCalcDefinition(cell);
    }

    lookupParentBottomCalcDefinition(cell: any) {
        return cell.getColumn()._column.parent.botCalcs.find((c: any) => c.field == cell?.getColumn().getField())?.definition;
    }

    getFormatterParams(definition: any): { symbol: string, decimal: string, thousand: string, precision: number } {
        let columnDef = definition?.formatterParams;

        return { symbol: columnDef?.symbol, decimal: columnDef?.decimalSeparator, thousand: columnDef?.thousand, precision: columnDef?.precision };
    }

    getPivotPercentCalculation(definition: any) {
        return definition?.isPivotPercentType;
    }

    getBigValue(definition: any) {
        return definition?.isBigValue
    }

    getNumberSymbol(cell: any, isBigValue:any, value: number) {
        //let columnDef = cell?._cell?.column?.definition;
        let columnDef = cell?.getColumn().getDefinition();
        let prefix = columnDef?.formatterParams?.prefix ?? this.context.numberFormat.formatterParams.prefix;
        let absoluteValue = Math.abs(value);

        if (isBigValue) {
            if (absoluteValue > 1e9)
                return prefix.billion;
            if (absoluteValue > 1e6)
                return prefix.million;
            if (absoluteValue > 1e3)
                return prefix.thousand;
        }
        return "";
    }

    getRealValue(value: number, IsBigValue: boolean) {
        if (!IsBigValue)
            return value;

        let absoluteValue = Math.abs(value);

        if (absoluteValue > 1e9)
            return (value / 1e9);
        if (absoluteValue > 1e6)
            return (value / 1e6);
        if (absoluteValue > 1e3)
            return (value / 1e3);

        return value;
    }

    number_format(number: number, decimals: number, dec_point: string, thousands_sep: string) {
        let n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            toFixedFix = function (_n: number, _prec: number) {
                let k = Math.pow(10, _prec);
                return Math.round(_n * k) / k;
            },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    setColumns(columns: any[]) {
        let me = this;
        let context = me.context;

        let aggColumn: any = me.initAggColumn(context);
        if (me.context.sumColumns)
            me.setbottomCalc(aggColumn, context);

        let isChildColumn = columns.find((item: any) => item.cssClass.includes("auto"));
        if (me.context.sumRows && !isChildColumn)
            columns.push(aggColumn);

        columns.forEach(c => me.setColumn(c, me, context, columns, isChildColumn));

        me.grid?.setColumns(columns);
        me.grid?.redraw(true);
    }

    setColumn(column: any, me: any, context: any, columns: any, isChildColumn: any) {
        let c: any = column;
        if (c.columns)
            this.setColumns(c.columns);
        else
            c.headerClick = (_e: any, _column: any) => me.setHeaderClick(_column);

        if (!context.sumColumns)
            return;

        let index = columns.filter((_c: any) => !context.sortFields.includes(_c.title)).indexOf(c);

        if ((c._datatype === "Number" || c.field === "_aggRows") && !c.field.startsWith("key") && c.title !== me.context.deltaHeader && c.title !== this.context.deltaValueHeader) {
            c.bottomCalc = index === 0 && !isChildColumn ? (_values: any[], _data: any[], _calcParams: any) => context.headerColumn : this.getColumnCalc(context.columnsAgregetion);
            c.bottomCalcFormatter = context.numberFormat.type;
            c.bottomCalcFormatterParams = { decimal: context.numberFormat.formatterParams.decimalSeparator, thousand: context.numberFormat.formatterParams.thousand, precision: context.numberFormat.formatterParams.precision, symbol: context.numberFormat.formatterParams.symbol, symbolAfter: "p" };
        }
        else
            c.bottomCalc = index === 0 ? (_values: any[], _data: any[], _calcParams: any) => context.headerColumn
                : (_values: any[], _data: any[], _calcParams: any) => "";
    }

    initAggColumn(context: any) {
        return {
            title: context.headerRow,
            field: "_aggRows",
            cssClass: "rows-summarize", headerHozAlign: "right", hozAlign: "right",
            width: context.rowSummarizeColumnWidth,
            minWidth: 150,
            isPivotPercentType: context.isPivotPercentType,
            isBigValue: context.isBigValue,
            formatter: context.numberFormat.type,
            formatterParams: {
                decimal: context.numberFormat.formatterParams.decimalSeparator,
                thousand: context.numberFormat.formatterParams.thousand,
                precision: context.numberFormat.formatterParams.precision,
                symbol: context.numberFormat.formatterParams.symbol,
                symbolAfter: "p"
            }
        };
    }

    setbottomCalc(aggColumn: any, context: any) {
        aggColumn.bottomCalc = this.getColumnCalc(context.columnsAgregetion);
        aggColumn.bottomCalcFormatter = context.numberFormat.type;
        aggColumn.bottomCalcFormatterParams = {
            decimal: context.numberFormat.formatterParams.decimalSeparator,
            thousand: context.numberFormat.formatterParams.thousand,
            symbol: context.numberFormat.formatterParams.symbol,
            symbolAfter: "p",
            precision: context.numberFormat.formatterParams.precision
        };
    }

    getColumnCalc(fn: string): "avg" | "max" | "min" | "sum" | "concat" | "count" | ((values: any[], data: any[], calcParams: {}) => any) {
        let _fn = fn.toLowerCase();
        switch (_fn) {
            case "median":
                return (values: any[], data: any[], _calcParams: any) => {
                    this.deleteObjects(data);
                    const mid = Math.floor(values.length / 2);
                    const nums = values.slice().sort((a, b) => a - b);
                    return nums.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2  // Median
                };
            case "avg":
            case "max":
            case "min":
            case "sum":
            case "concat":
            case "count":
                return _fn;
            default:
                return "sum";
        }
    }

    deleteObjects(data: any[]) {
        data.forEach((obj: any) => Object.keys(obj).forEach((key: any) => { if (key.startsWith("key")) delete obj[key]; }));
    }

    setHeaderClick(column: any) {
        let field = column.getDefinition().field;
        let title = column.getDefinition()._fieldName;
        this.context.onSortedCaller.invokeMethodAsync(this.context.onSortedDataFn, field, title)
    }

    setData(data: any[]) {
        if (this.context.sumRows && this.context.rowsAgregetion != "useMeasureFunction")
            data = this.calcRows(data);

        this.grid?.setData(data);
    }

    export(
        downloadType: Tabulator.DownloadType | ((columns: Tabulator.ColumnDefinition[], data: any, options: any, setFileContents: any) => any),
        fileName: string) {
        this.grid?.download(downloadType, fileName, {
            documentProcessing: (wb) => {
                for (let prop in wb.Sheets.Sheet1) {
                    let date_regex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/gm;
                    if (date_regex.test(wb.Sheets.Sheet1[prop].v))
                        wb.Sheets.Sheet1[prop].t = "d";
                    else if (wb.Sheets.Sheet1[prop].v == 'null')
                        wb.Sheets.Sheet1[prop].v = '';
                }
                return wb;

            }
        })
    }

    сopyDataToClipboard() {
        this.grid?.copyToClipboard();
    }

    resizeToParent() {
        let elRect = this.ref.parentElement.getBoundingClientRect();
        let height = (elRect.height - 8) + 'px';
        let width = elRect.width + 'px';
        let el = this.ref;
        el.style.width = width;
        el.style.maxHeight = height;
        el.style.display = 'inline-block';
        this.grid?.redraw();
    }

    setSize() {
        let elRect = this.ref.parentElement.getBoundingClientRect();
        let height = (elRect.height - 8) + 'px';
        let width = elRect.width + 'px';
        let el = this.ref;
        el.style.width = width;
        el.maxHeight = height;
        el.style.display = 'inline-block';
        this.grid?.redraw();
    }

    destroyGrid() {
        window.removeEventListener('resize', this.resizeToParent);
        this.grid?.destroy();
    }

    updateProperties(context: any): boolean {
        let isChangedIsPivot = context.isPivot !== this.context.isPivot;
        let isChangedGrouping = context.grouping !== this.context.grouping;
        let isChangedGroupTreeChildIndent = context.groupTreeChildIndent !== this.context.groupTreeChildIndent;
        let isChangedSumColumns = context.sumColumns !== this.context.sumColumns;

        for (const property in context) {
            this.context[property] = context[property];
        }

        return isChangedIsPivot || isChangedGrouping || isChangedGroupTreeChildIndent || isChangedSumColumns;
    }

    updateContext(context: any) {
        if (this.updateProperties(context)) {
            this.destroyGrid();
            this.createGridByRef(this.ref, this.context);
            this.grid?.redraw(true);
        }
        else {
            this.grid?.redraw(true);
        }
    }

    setMultipleSorter(orderdFields: any[]) {
        let sorters: any[] = [];

        if (this.context.sumColumnsSort != 0 && this.context.sumRows) {
            sorters = [{ column: "_aggRows", dir: this.getDirectionName(this.context.sumColumnsSort) }];
            this.grid?.setSort(sorters);
            return;
        }

        orderdFields?.forEach((f: { order: any, resultName: any, sortField: any }) => {
            this.pushSorters(sorters, f.sortField || f.resultName, f.order);
        })

        this.grid?.setSort(sorters);
    }

    pushSorters(sorters: any, field: any, order: any) {
        if (this.grid.columnManager.getColumnByField(field)) {
            sorters.push({ column: field, dir: this.getDirectionName(order) });
        }
    }

    getDirectionName(orderNum: number) {
        let dir = "none";
        if (orderNum == 1) dir = "asc";
        if (orderNum == 2) dir = "desc";
        return dir;
    }

    formatValue(cell: any) {
        if (this.isValidDate(cell)) {
            return this.dateToString(cell.value);
        }
        else if (this.isValidNumber(cell)) {
            if (Number.isInteger(cell.value)) {
                return this.intToString(cell.value);
            }
            let numDecimals = this.context.numDecimals || 2;
            return this.numberToString(cell.value, numDecimals);
        }
        return cell.value;
    }
    dateToString(value: any) {
        let date = new Date(value);
        return date.toLocaleDateString();
    }
    intToString(value: any) {
        return (Math.round(value * 100) / 100).toLocaleString(navigator.language);
    }
    numberToString(value: any, numDecimals: number) {
        return Intl.NumberFormat(navigator.language, { style: 'decimal', maximumFractionDigits: numDecimals, minimumFractionDigits: numDecimals }).format(value)
    }
    setNumDecimal(value: any, numDecimals: number) {
        return value.toFixed(numDecimals);
    }
    isValidDate(cell: any) {
        if (cell.colDef && cell.colDef._datatype && cell.colDef._datatype !== 'DateTime') {
            return false;
        }
        return !this.isValidNumber(cell) && Date.parse(cell.value);
    }

    isValidNumber(cell: any) {
        return typeof cell.value === 'number' && isFinite(cell.value);
    }

    setSelectedRow(index: number) {
        if (this?.grid?.getRows() ?? -1 > index)
            this?.grid?.getRowFromPosition(index).select();
    }
    setSelectedRows(indices: number[]) {
        indices.forEach(i => this.setSelectedRow(i));
    }
    deselectRow(index: any) {
        this?.grid?.deselectRow(index);
    }
    deselectAllRows(_index: any) {
        this?.grid?.deselectRow();
    }
    showLoaderOverlay() {
        this.ref.previousElementSibling.style.display = 'block';
    }
    hideLoaderOverlay() {
        this.ref.previousElementSibling.style.display = 'none';
    }

    calcRows(data: any[]) {
        data.forEach(d => this.calcRow(d));
        return data;
    }

    calcRow(data: any) {
        let d = data;
        let sum = 0;
        let avg = 0;
        let mContainer: any[] = [];
        for (const [key, value] of (Object as any).entries(d)) {
            if (typeof value == "number" && !this.context.sortFields.includes(key) && !key.startsWith("key") && key !== this.context.deltaHeader && key !== this.context.deltaValueHeader) {
                mContainer.push(value)

                if (this.context.rowsAgregetion === "avg")
                    avg += value;

                sum = this.calcSumSwitch(this.context.rowsAgregetion, value, sum, avg, mContainer);
            }
        }
        d._aggRows = sum;
    }

    calcSumSwitch(aggregation: any, value: any, sum: any, avg: any, mContainer: any) {
        switch (aggregation) {
            case "min":
                sum = Math.min.apply(Math, mContainer)
                break;
            case "max":
                sum = Math.max.apply(Math, mContainer)
                break;
            case "avg":
                sum = avg / mContainer.length
                break;
            case "median":
                const mid = Math.floor(mContainer.length / 2);
                const nums = [...mContainer].sort((a, b) => a - b);
                sum = mContainer.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
                break;
            default:
                sum += value
                break;
        }

        return sum;
    }

    isBlank(value: any) {
        return value?.length == 0 || value == null || value == undefined;
    }
}
