import { LoadTabulator } from './TabulatorEngine';

namespace SignalHub.Browser.Grids {

    export function Main() {

        LoadTabulator();
    }
}

SignalHub.Browser.Grids.Main();