import { TabulatorGrid } from './Tabulator';

export function LoadTabulator() {

    (window as any).TabulatorEngine = {
        createGridByRef: function (ref: any, context: any) {
            let grid = new TabulatorGrid().createGridByRef(ref, context);
            (window as any).ItemsManager.add(ref, grid);
        },

        destroyGridByRef: function (ref: any) {
            let manager = (window as any).ItemsManager;
            let grid = manager.get(ref);
            manager.remove(ref);
            grid?.destroyGrid();
        },

        updateContextByRef: function (ref: any, context: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.updateContext(context);
        },

        setMultipleSorterByRef: function (ref: any, orderdFields: any ) {
            let grid = (window as any).ItemsManager.get(ref);
            if (grid) {
                grid.setMultipleSorter(orderdFields);
            }
        },        

        setColumnsByRef: function (ref: any, columns: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.setColumns(columns);
        },

        setDataByRef: function (ref: any, data: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.setData(data);
        },

        setPivotDataSourceByRef: function (ref: any, data: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.setPivotDataSource(data);
        },

        exportByRef: function (ref: any, fileName: string) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.export('xlsx', fileName);
        },

        сopyDataToClipboardByRef: function (ref: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.сopyDataToClipboard();
        },
   
        setSelectedRow: function (ref: any, index: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.setSelectedRow(index);
        },

        resizeToParentByRef: function (ref: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.resizeToParent();
        },

        setSelectedRows: function (ref: any, indices: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.setSelectedRows(indices);
        },

        showNoRowsOverlayByRef(ref: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.showNoRowsOverlay();
        },

        showLoaderOverlayByRef(ref: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.showLoaderOverlay();
        },

        hideLoaderOverlayByRef(ref: any) {
            let grid = (window as any).ItemsManager.get(ref);
            grid?.hideLoaderOverlay();
        }
    }
}